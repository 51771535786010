<template>
  <div class="tutorial">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide class="swiper-slide">
        <img src="@/assets/img/static/howtouse/tutorial-pc-page1.png" />
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <img src="@/assets/img/static/howtouse/tutorial-pc-page2.png" />
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <img src="@/assets/img/static/howtouse/tutorial-pc-page3.png" />
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <img src="@/assets/img/static/howtouse/tutorial-pc-page4.png" />
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <img src="@/assets/img/static/howtouse/tutorial-pc-page5.png" />
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <img src="@/assets/img/static/howtouse/tutorial-pc-page6.png" class="clickable" @click="novelCreate" />
      </swiper-slide>
      <div slot="pagination" class="swiper-pagination" />
      <div slot="button-prev" class="swiper-button-prev" />
      <div slot="button-next" class="swiper-button-next" />
    </swiper>
    <router-link :to="{ name: 'home' }">
      <div class="button-start">さっそく執筆を開始する</div>
    </router-link>
    <router-link :to="{ name: 'home' }">
      <div class="button-close pc">×</div>
    </router-link>
  </div>
</template>
<script type="ts">
import Vue from "vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper"

export default Vue.extend({
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        speed: 1000,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
      },
    }
  },
  methods: {
    novelCreate() {
      this.$router.push({ name: "novelCreate" })
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "signUp") {
      next(async vm => await vm.$store.dispatch("generalModule/createSampleData"));
    } else {
      next();
    }
  }
});
</script>

<style lang="scss" scoped>
.tutorial {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .swiper {
    width: 100%;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 30px;

      img {
        max-height: 100%;
        max-width: 100%;

        &.clickable {
          cursor: pointer;
        }
      }
    }
  }
}

/*チュートリアル下部のボタンのレイアウト*/
a {
  text-decoration: none;
}

.button-start {
  text-align: center;
  text-decoration: none;
  width: 150px;
  margin: 30px 0;
  padding: 15px 50px;
  border: solid 1px rgb(71, 74, 77);
  background-color: rgb(243, 243, 242);
  color: rgb(71, 74, 77);
  border-radius: 30px;
}

.button-close {
  position: absolute;
  width: 36px;
  height: 36px;
  padding: 3px;
  top: 30px;
  right: 13%;
  font-size: 25px;
  text-align: center;
  background-color: #f3f3f2;
  border-color: #474a4d;
  color: #474a4d;
  border-radius: 50%;
  z-index: 1;
}
</style>

<style lang="scss">
@import "swiper/swiper.scss";
@import "swiper/components/navigation/navigation.scss";
@import "swiper/components/pagination/pagination.scss";
</style>
