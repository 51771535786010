
import Vue from "vue";
import axiosBase from "axios";
import { Auth } from "aws-amplify";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { Plan } from "@/lib/models/user";
import { isTwitterLogin } from "@/lib/twitterAuth";
import { createUrlWithUtmParams } from "@/lib/utils/url";
import { NolaItemId, NolaPageName } from "@/lib/utils/analytics";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_MICROCMS_API_KEY },
});

export default Vue.extend({
  data() {
    return {
      contents: [],
      couponTitle: "",
      coupon: undefined,
      queryPlan: undefined,
      isNotFound: false,
      loading: true,
    };
  },
  computed: {
    formatExpired() {
      if (this.coupon) {
        return format(this.coupon.expired, "YYYY/MM/DD", { locale: ja });
      }
      return "";
    },
  },
  async created() {
    this.queryPlan = this.$route.query.plan;

    // クエリパラメータに沿って認証の確認をする
    if (!this.queryPlan) {
      const user = (await Auth.currentCredentials().catch((err) => {
        if (err.code === "UserNotFoundException" || err.code === "NotAuthorizedException") {
          return;
        }
        if (err !== "No current user") {
          throw err;
        }
      })) as any;

      const isAuthenticated = user.authenticated;
      const isLogin = user && !user.code && (isTwitterLogin() || isAuthenticated);

      // 通常は未ログインユーザーを弾く
      if (!isLogin) {
        this.$router.push("/");
      }
    }

    let couponFree;
    let couponPremium;
    try {
      const res = await axios.get("/knowhow?limit=100");
      if (res.status !== 200) {
        throw res;
      }

      res.data.contents.forEach((c) => {
        if (c.type[0] === "chapter" || c.type[0] === "content") this.contents.push(c);
        else if (c.type[0] === "coupon_free") couponFree = c;
        else if (c.type[0] === "coupon_premium") couponPremium = c;
      });
    } catch (_) {
      this.isNotFound = true;
      return;
    }

    // 会員情報の取得
    if (this.queryPlan) {
      if (this.queryPlan === "premium") {
        this.couponTitle = "プレミアム会員クーポン";
        this.coupon = couponPremium;
      } else {
        this.couponTitle = "無料会員クーポン";
        this.coupon = couponFree;
      }
    } else {
      try {
        const plan = this.$store.getters["userModule/plan"] as Plan;
        switch (plan) {
          case Plan.monthly:
          case Plan.yearly:
          case Plan.school:
            this.couponTitle = "プレミアム会員クーポン";
            this.coupon = couponPremium;
            break;
          default:
            this.couponTitle = "無料会員クーポン";
            this.coupon = couponFree;
        }
      } catch (_) {
        this.isNotFound = true;
        return;
      }
    }

    this.loading = false;
  },
  methods: {
    toBr(text: string) {
      return text.replaceAll("\n", "<br>");
    },
    toDetail(id: string) {
      let link = `${process.env.VUE_APP_NOLANOVEL_WEB}/knowhow/${id}`;
      if (this.queryPlan) link += `?plan=${this.queryPlan}`;
      window.open(
        createUrlWithUtmParams(link, NolaPageName.KnowHow, NolaItemId.KnowHowViewDetail),
        process.env.VUE_APP_NOLANOVEL_NAME ?? ""
      );
    },
    displayedTagHtml(tag?: Array<string>) {
      if (tag) {
        const display = tag.map((value: string) => `<span class="mr-2" style="white-space: nowrap;">#${value}</span>`);
        return display.length > 0 ? display.join("\n") : "未設定";
      }
      return "未設定";
    },
    toStore() {
      window.open("https://nola0filmart.base.ec/");
    },
  },
});
