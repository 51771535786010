
import Vue from "vue";
import gql from "graphql-tag";
import { Plan } from "@/lib/models/user";
import { callApi } from "../../lib/api";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },

  async created() {
    window.scrollTo(0, 0);

    const { billingInfo } = await callApi(gql`
      query BillingInfo {
        billingInfo {
          plan
          stripeId
          platform
          cardInfo {
            exp_month
            exp_year
            last4
          }
        }
      }
    `);
    if (billingInfo.plan === 0) {
      this.$router.push({ name: "myPage" });
    }

    this.billingInfo = billingInfo;
    this.navigatePath = getNavigatePath(billingInfo);
  },

  data() {
    return {
      billingInfo: null,
      navigatePath: "unsubscriptionAnnounce",
    };
  },

  computed: {
    planName() {
      const { billingInfo } = this;
      if (billingInfo) {
        switch (billingInfo.plan) {
          default:
          case 0:
            return "無料会員";
          case 1:
            return "プレミアム会員";
          case 2:
            return "プレミアム会員（年間）";
        }
      }
      return "無料会員";
    },
    planPrice() {
      const { billingInfo } = this;
      if (billingInfo) {
        switch (billingInfo.plan) {
          default:
          case 0:
            return "0";
          case 1:
            return "300";
          case 2:
            return "2,800";
        }
      }
      return "0";
    },
  },
});

function getNavigatePath(billingInfo: BillingInfo) {
  switch (billingInfo.platform) {
    case "ios":
    case "android":
      return "confirmInAppPurchase";
    default:
      return "unsubscriptionAnnounce";
  }
}

interface Props {}

interface Data {
  billingInfo: BillingInfo | null;
  navigatePath: string;
}

interface Computed {
  planName?: string;
  planPrice?: string;
}

interface Methods {}

interface BillingInfo {
  plan: Plan;
  stripeId: string;
  platform: string;
  cardInfo: {
    // eslint-disable-next-line camelcase
    exp_month: string;
    // eslint-disable-next-line camelcase
    exp_year: string;
    last4: string;
  };
}
