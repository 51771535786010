
import Vue from "vue";
import gql from "graphql-tag";
import { callApi } from "../../lib/api";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;
      const result = await callApi(gql`
        mutation mutation {
          unsubscribe {
            result
          }
        }
      `);
      if (result) {
        this.sendGA();

        this.$router.replace({ name: "unsubscriptionCompleted" });
      }
      this.isSubmitting = false;
    },
    sendGA() {
      // イベント
      this.$ga.event("subscription", "unsubscribe", "サブスクリプション解除", 0);

      // User Category（カスタムディメンション）のセット
      this.$ga.set("dimension1", "無料会員");
    },
  },
});
