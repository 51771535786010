
import Vue from "vue";

export default Vue.extend({
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    // TODO: 参照されてないので、使わないなら削除する
    // showModal01() {
    //   const { descriptionModal01 } = this.$refs;
    //   (descriptionModal01 as any).show();
    // },
    // showModal02() {
    //   const { descriptionModal02 } = this.$refs;
    //   (descriptionModal02 as any).show();
    // },
    // showModal03() {
    //   const { descriptionModal03 } = this.$refs;
    //   (descriptionModal03 as any).show();
    // },
    // showModal04() {
    //   const { descriptionModal04 } = this.$refs;
    //   (descriptionModal04 as any).show();
    // },
    // showModal05() {
    //   const { descriptionModal05 } = this.$refs;
    //   (descriptionModal05 as any).show();
    // },
    // showModal06() {
    //   const { descriptionModal06 } = this.$refs;
    //   (descriptionModal06 as any).show();
    // },
    // hideModal() {
    //   const {
    //     descriptionModal01,
    //     descriptionModal02,
    //     descriptionModal03,
    //     descriptionModal04,
    //     descriptionModal05,
    //     descriptionModal06,
    //   } = this.$refs;
    //   (descriptionModal01 as any).hide();
    //   (descriptionModal02 as any).hide();
    //   (descriptionModal03 as any).hide();
    //   (descriptionModal04 as any).hide();
    //   (descriptionModal05 as any).hide();
    //   (descriptionModal06 as any).hide();
    // },
  },
});
