
import Vue from "vue";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  data() {
    return {
      page: "",
    };
  },
  created() {
    window.scrollTo(0, 0);

    if (this.$route.path.includes("/subscription")) {
      this.page = "subscription";
    } else if (this.$route.path.includes("/unsubscription")) {
      this.page = "unsubscription";
    }
  },
  computed: {
    title() {
      switch (this.page) {
        case "subscription":
          return "ご登録ありがとうございます";
        case "unsubscription":
          return "プレミアム会員を退会しました";
        default:
          return "";
      }
    },
    message() {
      switch (this.page) {
        case "subscription":
          return "<div>お支払いいただくお金はNolaをもっと良くするための開発費や、作家様の出版などの取り組みにつなげるために大切に使わせていただきます。</div><div>また、Nolaをもっと使いやすい執筆環境にするため、機能のご要望やアイデアなどをいただけると大変嬉しく思います。</div><div>まだまだ至らない点も多く大変恐縮ですが、これからもNolaをよろしくお願いいたします。</div>";
        case "unsubscription":
          return "<div>これまでNolaのプレミアム会員をご利用くださりありがとうございました。ツールとして至らなかった点については、これから一つ一つ見直して改善していきたいと思います。</div><div>そして、さらにより良いツールとなった際に、また手にとっていただける機会がいただけたら作り手として大変嬉しく存じます。。</div><div>無料会員でも十分に執筆にお役立ていただけるツールとできるよう努めてまいりますので、これからもNolaを何卒よろしくお願いいたします。</div>";
        default:
          return "";
      }
    },
  },
});
