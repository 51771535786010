import { render, staticRenderFns } from "./NotFoundPage.vue?vue&type=template&id=5add1e9a&scoped=true"
var script = {}
import style0 from "./NotFoundPage.vue?vue&type=style&index=0&id=5add1e9a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5add1e9a",
  null
  
)

export default component.exports