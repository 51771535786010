
import Vue from "vue";
import RegisterCreditCardForm from "@/components/organisms/subscription/RegisterCreditCardForm.vue";
import { v4 as uuidv4 } from "uuid";
import CheckboxMarkedCircleIcon from "icons/CheckboxMarkedCircle.vue";
import { SubscriptionInput, VerifyPromotionCodeInput } from "@/lib/models/billing";
import { BillingClient } from "@/lib/clients";
import { Plan } from "@/lib/models/user";

const billingClient = new BillingClient();

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { RegisterCreditCardForm, CheckboxMarkedCircleIcon },
  data() {
    return {
      loaded: false,
      isSubmitting: false,
      page: null,
      code: "",
      promotionCode: "",
      couponDescription: "",
      couponError: "",
    };
  },
  async created() {
    window.scrollTo(0, 0);

    if (this.$route.path.includes("monthly")) {
      this.page = "monthly";
    } else if (this.$route.path.includes("yearly")) {
      this.page = "yearly";
    } else if (this.$route.path.includes("modify/payment")) {
      this.page = "modifyPayment";
    }

    const plan = await billingClient.fetchPlan();

    if (
      (plan === Plan.free && this.page === "modifyPayment") ||
      (plan !== Plan.free && this.page !== "modifyPayment")
    ) {
      this.$router.push({ name: "myPage" });
    }
  },
  methods: {
    async onSubscribe() {
      this.isSubmitting = true;
      const result = await (this.$refs.registerCreditCardForm as any).register();

      try {
        if (result) {
          const res = await this.subscribe();
          if (res) {
            this.$router.replace({ name: "subscriptionCompleted" });
          }
        }
      } finally {
        this.isSubmitting = false;
      }
    },
    async subscribe() {
      const { page, promotionCode } = this;

      let plan = 0;
      switch (page) {
        case "monthly":
          plan = 1;
          break;
        case "yearly":
          plan = 2;
          break;
        default:
          return;
      }

      const payload: SubscriptionInput = {
        plan,
        promotionCode,
      };

      if (!promotionCode) {
        delete payload.promotionCode;
      }

      const result = await billingClient.subscribe(payload);

      this.sendGA(plan);

      return result;
    },
    async onModify() {
      this.isSubmitting = true;
      const result = await (this.$refs.registerCreditCardForm as any).register();
      this.isSubmitting = false;
      if (result) {
        this.$notify({
          title: "保存しました",
          type: "success",
        });

        this.$router.push({ name: "subscriptionModify" });
      }
    },
    sendGA(plan) {
      const id = uuidv4();
      let userCategory;
      let name = "";
      let price;

      switch (plan) {
        case 1:
          userCategory = "月額会員";
          name = "月額プラン";
          price = "300";
          break;
        case 2:
          userCategory = "年額会員";
          name = "年額プラン";
          price = "2800";
          break;
        default:
          return;
      }

      // イベント
      this.$ga.event("subscription", `subscribe-${this.page}`, `${name}登録`, Number(price));

      // User Category（カスタムディメンション）のセット
      this.$ga.set("dimension1", userCategory);

      // eコマース情報
      this.$ga.ecommerce.addTransaction({
        id,
        revenue: price,
        // currency: "JPY", NOTE: Universal Analyticsで使える
      });

      this.$ga.ecommerce.addItem({
        id,
        name,
        price,
        quantity: 1,
      });

      this.$ga.ecommerce.send();
    },
    async onApplyCode() {
      const { code, page } = this;
      if (!code || !page) return;

      const payload: VerifyPromotionCodeInput = {
        code,
        plan: page,
      };
      const verified = await billingClient.verifyPromotionCode(payload);
      const { result, data } = verified;
      if (!result || !data) {
        this.promotionCode = "";
        this.couponError = "無効なクーポンコードです。";
      } else {
        const { id, description } = data;

        this.promotionCode = id;
        this.couponDescription = description || "";
      }
    },
  },
  computed: {
    planName() {
      switch (this.page) {
        case "monthly":
          return "プレミアム会員（月額）";
        case "yearly":
          return "プレミアム会員（年額）";
        default:
          return "無料会員";
      }
    },
    planPrice() {
      switch (this.page) {
        case "monthly":
          return "300";
        case "yearly":
          return "2,800";
        default:
          return "0";
      }
    },
    description() {
      switch (this.page) {
        case "monthly":
        case "yearly":
          return "Nolaプレミアムのご利用につき、決済情報のご入力をお願いします。なお、Nolaでは決済サービスとして数百カ国の企業で利用されているStripeという決済サービスを導入しております。";
        case "modifyPayment":
          return "変更するお支払い情報のご入力をお願いします。";
        default:
          return "";
      }
    },
    applied() {
      const { promotionCode } = this;
      return !!promotionCode;
    },
  },
  watch: {
    code() {
      this.promotionCode = "";
      this.couponDescription = "";
      this.couponError = "";
    },
  },
});

interface Props {}

interface Data {
  loaded: boolean;
  isSubmitting: boolean;
  page: Page | null;
  code: string;
  promotionCode: string;
  couponDescription: string;
  couponError: string;
}

interface Computed {
  planName: string;
  planPrice: string;
  description?: string;
  applied: boolean;
}

interface Methods {
  onSubscribe(): Promise<void>;
  subscribe(): Promise<any>;
  onModify(): Promise<void>;
  sendGA(plan: number): void;
  onApplyCode(): Promise<void>;
}

type Page = "monthly" | "yearly" | "modifyPayment";
