
import Vue from "vue";
import { Auth } from "aws-amplify";
import TextField from "@/components/molecules/TextField.vue";
import { isTwitterLogin } from "@/lib/twitterAuth";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { TextField },
  data() {
    return {
      email: "",
      password: "",
      confirmCode: "",
      confirmMode: false,
      errorMessage: "",
      message: "",
      identityId: null,
    };
  },
  async mounted() {
    if (!isTwitterLogin()) {
      this.$router.push({ name: "home" });
    }

    const currentCredentials: any = await Auth.currentCredentials();
    const identityId = currentCredentials.data.IdentityId;
    this.identityId = identityId;
  },
  methods: {
    async submit() {
      try {
        const { email, password, identityId } = this;
        await Auth.signUp({
          username: email,
          password,
          attributes: {
            profile: JSON.stringify({ migrateIdentityId: identityId, migrateStatus: "ready" }),
          },
        });
        this.confirmMode = true;
        this.errorMessage = "";
      } catch (err) {
        this.message = "";
        if (err.code === "UsernameExistsException") {
          this.errorMessage = `メールアドレスは既に登録済みです`;
        } else if (err.code === "InvalidParameterException" || err.code === "InvalidPasswordException") {
          this.errorMessage = `パスワードは7文字以上かつ大文字・小文字を含む文字列にしてください`;
        } else if (err.code === "LimitExceededException") {
          this.errorMessage = "送信回数制限に達しました。しばらくしてから再度やり直してください。";
        } else {
          this.errorMessage = `エラーが発生しました: ${err}`;
        }
      }
    },
    async submitConfirmCode() {
      try {
        const { email, /* password, */ confirmCode } = this;

        await Auth.confirmSignUp(email, confirmCode);
        localStorage.clear();

        alert("メールアドレスの登録が完了しました！");
        this.message = "メールアドレスの登録が完了しました！";
        this.$router.push({ name: "announce" });
      } catch (err) {
        this.message = "";
        if (err.code === "UsernameExistsException") {
          this.errorMessage = `メールアドレスは既に登録済みです`;
        } else if (err.code === "InvalidParameterException" || err.code === "InvalidPasswordException") {
          this.errorMessage = `パスワードは7文字以上かつ大文字・小文字を含む文字列にしてください`;
        } else if (err.code === "LimitExceededException") {
          this.errorMessage = "送信回数制限に達しました。しばらくしてから再度やり直してください。";
        } else {
          this.errorMessage = `エラーが発生しました: ${err}`;
        }
      }
    },
    async resend() {
      const { email } = this;
      await Auth.resendSignUp(email);
      this.message = "確認コードを再送しました";
    },
  },
});
